// @flow
import React, { useState, useEffect } from "react";
import Truck from "react-feather/dist/icons/truck";
import Calendar from "react-feather/dist/icons/calendar";

type Props = {
  remaining_time: number,
  path: string,
  seed_file: boolean,
  send_at: ?string,
  widget_copy: {
    title: string,
    confirm_button: string,
    seed_file: { description: string },
    default: { description: string }
  }
};

function CancelWidget({
  remaining_time: initialRemainingTime,
  path,
  seed_file,
  send_at,
  widget_copy
}: Props): React$Node {
  const [remainingTime, setRemainingTime] = useState<number>(initialRemainingTime - 30);

  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTime((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  function formatTimeString(number: number): string {
    const minutes = Math.floor(number / 60);
    const seconds = number - minutes * 60;

    return minutes > 0 ? `${minutes}m ${seconds}s` : `${seconds}s`;
  }

  if (remainingTime <= 0) return null;

  const token = document.querySelector("meta[name=csrf-token]").content;
  const recipient_source_key = seed_file ? "seed_file" : "default";
  const Icon = send_at ? Calendar : Truck;

  return (
    <div className="mb-6 border border-jade-300 card bg-jade-200">
      <h2 className="flex items-center gap-2 text-lg font-bold text-jade-900">
        <span className="p-3 rounded-full bg-jade-300">
          <Icon size={18} strokeWidth={2} className="w-5 h-5" />
        </span>
        <div>{widget_copy.title}</div>
      </h2>
      <CountdownMessage
        suffix={widget_copy[recipient_source_key].description}
        remaining_time={formatTimeString(remainingTime)}
      />
      <div className="flex gap-4 mt-4">
        <form action={path} method="POST">
          <input type="hidden" name="_method" value="DELETE" />
          <input type="hidden" name="authenticity_token" value={token} />
          <button className="bg-white border-jade-300 slm-button secondary" type="submit">
            Cancel Send
          </button>
        </form>
        <form action={path} method="POST">
          <input type="hidden" name="_method" value="PATCH" />
          <input type="hidden" name="authenticity_token" value={token} />
          <input type="hidden" id="overridden_at" name="override" value="1" />
          <button className="slm-button" type="submit">
            {widget_copy.confirm_button}
          </button>
        </form>
      </div>
    </div>
  );
}

function CountdownMessage({ remaining_time, suffix }) {
  return (
    <div className="text-jade-900">
      In <strong>{remaining_time}</strong> {suffix}
    </div>
  );
}

export default CancelWidget;
