import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["select1", "select2", "frame"];

  connect() {
    this.select1Target.addEventListener("change", this.updateFrame.bind(this));
    this.select2Target.addEventListener("change", this.updateFrame.bind(this));
  }

  updateFrame() {
    const value1 = this.select1Target.value;
    const value2 = this.select2Target.value;

    if (value1 && value2) {
      const url = new URL(this.frameTarget.dataset.selectionUrl, window.location.origin);
      url.searchParams.set("recipient_source", value1);
      url.searchParams.set("external_source_type", value2);
      this.frameTarget.src = url.pathname + url.search;

      // show turbo frame
      this.toggleHidden(this.frameTarget.parentElement, false);
    } else {
      this.toggleHidden(this.frameTarget.parentElement, true);
    }
  }

  toggleHidden(element, hide) {
    element.classList.toggle("hidden", hide);
  }
}